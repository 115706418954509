import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {MaterialModule} from "../shared/material/material.module";
import {FormsModule} from "@angular/forms";

// public pages
import {PublicComponent} from './public.component';
import {HomeComponent} from './home/home.component';

// not found
import {NotFoundComponent} from './not-found/not-found.component';

const routes: Routes = [

    // public page
    {path: '', component: HomeComponent, pathMatch: 'full', title: "DerendaApp"},

    // not found
    {path: '**', component: NotFoundComponent}

];

@NgModule({
    declarations: [
        PublicComponent,
        HomeComponent,
        NotFoundComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MaterialModule,
        FormsModule
    ]
})
export class PublicModule {
}
