import {Component} from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent {

    public title = 'DerendaApp';
    public counter = 0;
    public showCounter = false;

    public increase(): void {
        this.counter += 1;
    }

    public reduce(): void {
        this.counter -= 1;
        if (this.counter <= 0) this.counter = 0;
    }

    public reset(): void {
        this.counter = 0;
        console.log('reset');
    }
}
